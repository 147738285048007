.headerlogoComp {
  width: 35px;
  height: 35px;
}
.headerlogowrapper {
  margin-left: 45px;
  margin-top: 20px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
}
.LogoHeadertext {
  color: #212121;
  font-family: Segoe UI;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 20px;
  margin-left: 14px;
}

.addnewbutton:hover {
  background-color: #5568d6;
}
.addnewwrapper {
  display: flex;
  align-items: right;
  justify-content: right;
  margin-top: 20px;
}

.logoutbutton:hover {
  color: #f14e58;
  background-color: transparent;
}
.logoutbuttonwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.search-box {
  width: 300px;
  height: 32px;
  box-shadow: 0 4px 8px 0 rgba(118, 91, 91, 0.2);
  padding-left: 30px;
  text-transform: capitalize;
  background: #ffffff;
  border: 0.7px solid #6b6b6b;
  border-radius: 8px;
  background-repeat: no-repeat;
  font-size: normal;
}
.username {
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.usercontactno {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 22px;
}
.userpfp {
  height: 30px;
  width: 30px;
  border-radius: 3rem;
  margin-top: 26px;
  margin-bottom: 26px;
  margin-left: 26px;
  margin-right: 10px;
}

.Usernamewrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.partientcardwrapper {
  border: 1px solid #d0cfcf;
  border-radius: 12px;
  height: 70px;
  margin-top: 14px;
  padding-left: 60px;
  cursor: pointer;
}
.partientcardwrapper:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.panelwrapper {
  border: 1px solid #d0cfcf;
  border-radius: 12px;
  width: 220px;
  height: 70px;
  margin-top: 12px;
  padding-left: 30px;
  cursor: pointer;
  display: flex;
}
.panelwrapper:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.BasicDetailsLabel {
  width: 67px;
  height: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025rem;
  color: #6b6b6b;
}

th,
td {
  padding-left: 20px;
}
.userdetailswrapper {
  margin-top: 30px;
}
.scroller_style {
  overflow: "scroll";
}
::-webkit-scrollbar {
  display: none;
}
.datefilter {
  width: 100%;
}
.docgroups {
  width: 200px;
  height: 40px;
  font-size: medium;
}
option {
  font-size: medium;
}
select {
  border-radius: 6px;
  align-items: center;
}
